<template>
  <Blocks :blocks="config" class="policy-page" />
</template>

<script>
import Config from '@/config/policy'
import { Blocks } from '@kvass/pagebuilder'

export default {
  computed: {
    config() {
      return Config['policy'].call(this)
    },
  },

  metaInfo() {
    return {
      title: 'Personvern',
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.policy-page {
  background-color: var(--primary);

  .policy-content {
    padding-block: 120px;
    @include respond-below('phone') {
      padding-bottom: 0;
    }
  }

  .policy-wrapper {
    @include respond-below('phone') {
      flex-direction: column !important;
      align-content: center;
    }
  }

  .policy-link {
    padding: 2rem;
    background: white;
    color: var(--primary);
    max-width: 600px;
    width: calc((100% / 3) - 30px + 30px / 3);
    min-width: 300px;
    white-space: nowrap;
    text-decoration: none;
    font-family: var(--secondary-font);
    font-size: 1.25rem;

    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s,
      -webkit-box-shadow 0.3s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);

    &:hover {
      transform: scale(1.03);
    }
  }
}
</style>
